export const navBarButtonStyle = {height: "19px", padding: "0px", border: "0px"};
export const navBarCenterButtonStyle = {width: "90px"};

export const OboLink = {
    fontFamily: "Arial, Helvetica, San-Serif, sans-serif",
    fontSize: "x-small",
    underline: "none",
    textDecorationLine: "none",
    textDecorationColor: "#000000",
    color: "#000000",
    width: "100%",
    padding: "3px",
    margin: "0px"
}