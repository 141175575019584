import {config} from "../config"
import LocalBackgroundImage from "../Images/background-dev-environment.png"
import DevBackgroundImage from "../Images/background-dev-environment.png"
import QaBackgroundImage from "../Images/background-qa-environment.png"
import ProdBackgroundImage from "../Images/background-prod-environment.png"

export function BackgroundImage() {
    switch (config.ENVIRONMENT) {
        case 'local':
            return LocalBackgroundImage;
        case 'dev':
            return DevBackgroundImage;
        case 'qa':
            return QaBackgroundImage;
        case 'prod':
            return ProdBackgroundImage;
        default:
            return;
    }
}