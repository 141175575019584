import { MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { ApiProgress } from "../../Utilities/ApiProgress";
import { useProductSettingsContext } from "./context/ProductSettingsContext";
import { ProductSettingsDistributorGrid } from "./components/ProductSettingsDistributorGrid";
import { DISTRIBUTOR_LIST_OPTIONS } from '../../Enums/DistributorListOptions';

const ProductSettings = () => {
    const {
        dataLoaded,
        productDefaults,
        distributorListSelectedOption,
        setDistributorListSelectedOption
    } = useProductSettingsContext();

    return (dataLoaded ? <Paper elevation={1} sx={{ padding: 2, margin: 2 }}>
        <Stack direction='column' spacing={1}>
            <Stack direction='row' spacing={2}>
                <Typography
                    variant='h4'>{productDefaults.brandFamily} {productDefaults.description} ({productDefaults.sku})</Typography>
                <TextField
                    id="outlined-select-distributors"
                    select
                    size='small'
                    value={distributorListSelectedOption}
                    onChange={(event) => {
                        setDistributorListSelectedOption(event.target.value);
                    }}
                    sx={{ minWidth: '150px' }}
                >
                    {DISTRIBUTOR_LIST_OPTIONS.map(option => {
                        return (<MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>)
                    })}
                </TextField>
            </Stack>
            <div style={{ width: "100%", height: "70vh" }}>
                <ProductSettingsDistributorGrid />
            </div>
        </Stack>
    </Paper> : <ApiProgress />)
}

export default ProductSettings;