import {createTheme, responsiveFontSizes} from "@mui/material";

export const theme = customTheme();

function customTheme() {
    let customTheme = createTheme({
        palette: {
            primary: {
                main: '#0A2447'
            },
            secondary: {
                main: '#FFFFFF'
            }
        },
        typography: {
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(","),
            fontSize: 24
        },
    });

    customTheme = responsiveFontSizes(customTheme);

    return customTheme;
}