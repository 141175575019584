import {Link, Stack} from "@mui/material";
import {GetObo2Link, GetOboLink} from "../../Utilities/NavigationMenuLinks";
import React from "react";
import arrowImage from "../../Images/OBO1/next.gif";
import {OboLink} from "../../Styles/Styles";


export default function MenuLink({id, lock, obo2}) {
    const entry = obo2 ? GetObo2Link(id) : GetOboLink(id);
    if (!entry) return null;

    return (<Stack direction="row" alignItems="center" style={{height: "21px"}} spacing={.25}>
        <img src={arrowImage} alt="Arrow" style={{width: "5px", height: "9px"}}/>
        {lock ? <span className="glyphicon glyphicon-lock"
                      style={{height: "10px", width: "10px", fontSize: "x-small", fontFamily: "Glyphicons Halflings", fontWeight: "normal"}}></span> : null}
        <Link href={entry.path} style={OboLink}>{entry.label}</Link>
    </Stack>);
}