import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo'
import SearchIcon from '@mui/icons-material/Search'
import {
    Box,
    Button,
    Checkbox,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import React, { useEffect, useState } from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import { useSKUFulfillmentTypesContext } from '../../../Contexts/skuFulfillmentTypesContext'
import { useSKUOfferingStatusesContext } from '../../../Contexts/skuOfferingStatusesContext'
import { useProductSettingsContext } from '../context/ProductSettingsContext'
import {
    PutProductDistributorFulfillmentType,
    PutProductDistributorOfferingStatus,
    PutProductSettingDistributorOverride,
} from '../hooks/useFetchProductSettingsDistributorOverrides'
import { DistributorTableRow } from './DistributorTableRow'
import { DistrictRow } from './DistrictRow'
import { DivisionRow } from './DivisionRow'
import { RegionRow } from './RegionRow'


const VirtuosoTableComponents = {
    // eslint-disable-next-line react/display-name
    Scroller: React.forwardRef((props, ref) => (<TableContainer component={Paper} {...props} ref={ref} />)),
    Table: (props) => (<Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} size="small" />),
    // eslint-disable-next-line react/prop-types, no-unused-vars
    TableRow: ({ item, ...props }) => <TableRow {...props} />,
    // eslint-disable-next-line react/display-name
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

export const ProductSettingsDistributorGrid = () => {
    const {
        productSettingsDistributorTable,
        updateProductSettingsDistributorTable,
        skuFulfillmentTypesByCustomerType,
        getPossibleSkuFulfillmentTypes,
        productDefaults,
        isChanged,
        selected,
        searchString,
        setSearchString,
        selectAllLegalRows,
        removeSelectedRows,
        unfilteredProductSettingsDistributorTable
    } = useProductSettingsContext();
    const { skuOfferingStatuses } = useSKUOfferingStatusesContext();
    const skuOfferingStatusesForUI = [{
        id: 0, description: "Ignore", skuFulfillmentOverride: null
    }].concat(skuOfferingStatuses);
    const { skuFulfillmentTypes } = useSKUFulfillmentTypesContext();
    const skuFulfillmentTypesForUI = [{ id: 0, description: "Ignore" }].concat(skuFulfillmentTypes);
    const [palletQuantity, setPalletQuantity] = useState("");
    const [palletDivisor, setPalletDivisor] = useState("");
    const [unitWeight, setUnitWeight] = useState("");
    const [offeringStatus, setOfferingStatus] = useState(0);
    const [skuFulfillmentType, setSkuFulfillmentType] = useState(0);
    const [isCopySelectedEnabled, setIsCopySelectedEnabled] = useState(false);

    useEffect(() => {
        setIsCopySelectedEnabled(isCopyToSelectedEnabled());
    }, [selected, palletQuantity, palletDivisor, unitWeight, offeringStatus, skuFulfillmentType]);

    const handleCopyClick = () => {
        let selectedRows = unfilteredProductSettingsDistributorTable.filter(row => selected.has(row.id));
        let updateObject;
        if (palletQuantity !== "") {
            updateObject = { ...updateObject, palletQuantity: palletQuantity };
        }
        if (palletDivisor !== "") {
            updateObject = { ...updateObject, palletDivisor: palletDivisor };
        }
        if (unitWeight !== "") {
            updateObject = { ...updateObject, unitWeight: unitWeight };
        }
        if (offeringStatus !== 0) {
            updateObject = {
                ...updateObject, skuOfferingStatus: skuOfferingStatuses.find(status => status.id === offeringStatus)
            };
        }

        let combinedRows = selectedRows.map(row => {
            return { row: row, updated: { ...row, ...updateObject } };
        })

        combinedRows = combinedRows.map(combinedRow => {
            let tempOverride = combinedRow.updated.skuOfferingStatus?.skuFulfillmentTypeOverride;
            let fulfillmentType = getPossibleSkuFulfillmentTypes(tempOverride, combinedRow.updated.type)?.skuFulfillmentTypes.find(type => type.id === skuFulfillmentType) ? skuFulfillmentType : combinedRow.row.skuFulfillmentType;
            let possibleSkuFulfillmentTypes = getPossibleSkuFulfillmentTypes(tempOverride, combinedRow.updated.type);

            if (possibleSkuFulfillmentTypes.skuFulfillmentTypes.find(type => type?.id === skuFulfillmentType)) { //requested fulfillment type is valid
                fulfillmentType = skuFulfillmentTypesByCustomerType.find(type => type.customerType === 0).skuFulfillmentTypes.find(type => type.id === skuFulfillmentType);
            } else {
                fulfillmentType = combinedRow.row.skuFulfillmentType
                if (!possibleSkuFulfillmentTypes.skuFulfillmentTypes.find(type => type?.id === fulfillmentType?.id)) { //original fulfillment type is not valid, set to first valid
                    fulfillmentType = possibleSkuFulfillmentTypes.skuFulfillmentTypes[0];
                }
            }

            if (tempOverride) {
                fulfillmentType = skuFulfillmentTypesByCustomerType.find(type => type.customerType === 0).skuFulfillmentTypes.find(type => type.id === tempOverride);
            }

            combinedRow.updated = { ...combinedRow.updated, skuFulfillmentType: fulfillmentType };
            return combinedRow;
        });


        updateProductSettingsDistributorTable(combinedRows.map(combined => combined.updated));
        combinedRows.forEach(async combinedRow => {
            if (isChanged(combinedRow.updated, combinedRow.row)) {
                await updateProductSettingsDistributorOverride(combinedRow.updated, combinedRow.row);
                await PutProductDistributorOfferingStatus(productDefaults, combinedRow.updated);
                if (combinedRow.updated.skuFulfillmentType) {
                    await PutProductDistributorFulfillmentType(productDefaults, combinedRow.updated);
                }
            }
        });
    }

    const isCopyToSelectedEnabled = () => {
        return (!selected?.size > 0 || (palletQuantity === "" && palletDivisor === "" && unitWeight === "" && offeringStatus === 0 && skuFulfillmentType === 0)) ?? true;
    }

    const fixedHeaderContent = () => {
        return (<React.Fragment>
            <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={selected.size > 0 && selected.size < productSettingsDistributorTable.filter(row => row.legal !== false).length}
                        checked={selected.size > 0 && selected.size === productSettingsDistributorTable.filter(row => row.legal !== false).length}
                        onChange={() => {
                            const allLegalIds = productSettingsDistributorTable.filter(row => row.legal !== false);
                            if (selected.size > 0 && selected.size === allLegalIds.length) {
                                removeSelectedRows();
                            } else {
                                selectAllLegalRows();
                            }
                        }}
                    />
                </TableCell>
                <TableCell width="35%">
                    <Typography variant={headerVariant}>
                        All Distributors
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography variant={headerVariant}>Legal</Typography>
                    <div>(Yes)</div>
                </TableCell>
                <TableCell align="center">
                    <Typography variant={headerVariant}>Pallet Quantity</Typography>
                    <div>({productDefaults.palletQuantity})</div>
                </TableCell>
                <TableCell align="center">
                    <Typography variant={headerVariant}>Pallet Divisor</Typography>
                    <div>({productDefaults.palletDivisor})</div>
                </TableCell>
                <TableCell align="center">
                    <Typography variant={headerVariant}>Unit Weight</Typography>
                    <div>({productDefaults.unitWeight})</div>
                </TableCell>
                <TableCell align="center">
                    <Typography variant={headerVariant}>Offering Status</Typography>
                    <div>(Not Offered)</div>
                </TableCell>
                <TableCell align="center">
                    <Typography variant={headerVariant}>Fulfillment Method</Typography>
                    <div>&nbsp;</div>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell colSpan={2}>
                    <TextField
                        id="search"
                        size='small'
                        placeholder={'Search by id, name, legal status ("legal", "illegal"), offering status, or fulfillment type'}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>),
                        }}
                        value={searchString}
                        onChange={(event) => {
                            setSearchString(event.target.value);
                        }}
                        sx={{ minWidth: '400px' }}
                        fullWidth />
                </TableCell>
                <TableCell align="center">
                    <Button
                        variant="contained"
                        disabled={isCopySelectedEnabled}
                        startIcon={<ContentPasteGoIcon />}
                        onClick={handleCopyClick}>
                        Copy To Selected
                    </Button>
                </TableCell>
                <TableCell align="center" sx={{ padding: '0px' }}>
                    <TextField
                        placeholder="Ignore"
                        size="small"
                        type="number"
                        value={palletQuantity}
                        onChange={event => setPalletQuantity(event.target.value)}
                    />
                </TableCell>
                <TableCell align="center" sx={{ padding: '0px' }}>
                    <TextField
                        placeholder="Ignore"
                        size="small"
                        type="number"
                        value={palletDivisor}
                        onChange={event => setPalletDivisor(event.target.value)}
                    />
                </TableCell>
                <TableCell align="center" sx={{ padding: '0px' }}>
                    <TextField
                        placeholder="Ignore"
                        size="small"
                        type="number"
                        value={unitWeight}
                        onChange={event => setUnitWeight(event.target.value)}
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        id="outlined-select-fulfillment"
                        select
                        value={offeringStatus}
                        size="small"
                        fullWidth
                        onChange={async (event) => {
                            setOfferingStatus(event.target.value)
                        }}
                    >
                        {skuOfferingStatusesForUI.map(status => <MenuItem key={status.id}
                            value={status.id}>
                            {status.description}
                        </MenuItem>)}
                    </TextField>
                </TableCell>
                <TableCell align="center">
                    <TextField
                        id="outlined-select-fulfillment"
                        select
                        value={skuFulfillmentType}
                        size="small"
                        fullWidth
                        onChange={async (event) => {
                            setSkuFulfillmentType(event.target.value)
                        }}
                    >
                        {skuFulfillmentTypesForUI.map(fulfillmentType => <MenuItem key={fulfillmentType.id}
                            value={fulfillmentType.id}>
                            {fulfillmentType.description}
                        </MenuItem>)}
                    </TextField>
                </TableCell>
            </TableRow>
        </React.Fragment>);
    }

    const handleUpdateRow = async (updatedRow) => {
        let originalRow = productSettingsDistributorTable.find(row => row.id === updatedRow.id);
        if (isChanged(updatedRow, originalRow)) {
            updateProductSettingsDistributorTable([updatedRow]);
        }
    }

    const updateProductSettingsDistributorOverride = async (updatedRow, originalRow) => {
        if (isChanged(updatedRow, originalRow)) {
            await PutProductSettingDistributorOverride(productDefaults, updatedRow)
        }
    };

    const RowComponent = (_index, row) => {
        switch (row.kind.value) {
            case 0:
                return (<DivisionRow row={row} />)
            case 1:
                return (<RegionRow row={row} />)
            case 2:
                return (<DistrictRow row={row} />)
            default:
                return (<DistributorTableRow row={row} handleUpdateRow={handleUpdateRow}
                    onSaveProductSettingsDistributorOverride={updateProductSettingsDistributorOverride}
                    skuFulfillmentTypesByCustomerType={skuFulfillmentTypesByCustomerType}
                    isChanged={isChanged} />)
        }
    }

    return (<Box
        sx={{
            height: "100%", width: "100%", "& .Division": {
                backgroundColor: "#3A5688", color: "#f8f9fa",
            }, "& .Region": {
                backgroundColor: "#CCD6E0", color: 'rgba(0, 0, 0, 0.87)',
            }, "& .District": {
                backgroundColor: "#E5E5E5", color: 'rgba(0, 0, 0, 0.87)',
            },
        }}
    >
        <Paper elevation={1} sx={{ height: '100%', width: '100%' }}>
            <TableVirtuoso
                data={productSettingsDistributorTable}
                components={VirtuosoTableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={RowComponent}
            />
        </Paper>
    </Box>);
}

const headerVariant = 'h6'