import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {config} from "../config";

const url = 'api/notifications'

export default function ModalNotificationsPopup() {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetch(url)
            .then(async (response) => {
                const isJson = response.headers
                    .get("content-type")
                    ?.includes("application/json");
                const data = isJson && (await response.json());

                if (!response.ok) {
                    setOpen(false);
                } else {
                    setOpen(data);
                }
            }).catch((err) => setOpen(false))
    }, []);

    const handleClose = () => {
        window.location.replace(config.OBO_URL + "/logout.aspx");
    };

    const handleAccept = () => {
        fetch(url, {method: 'POST'}).catch()
        setOpen(false);
    }

    return (<Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
        fullWidth={true}
    >
        <DialogTitle id="alert-dialog-title">
            <Typography
                sx={{fontSize: '16px', textAlign: "center", textDecoration: "underline", fontWeight: 'medium', backgroundColor: '#dbebd1', padding: '8px', color: '#000000'}}>
                Please accept the Monthly Forecast alert to access OBO
            </Typography>
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description" textAlign='center'>
                <Typography variant='body' sx={{fontSize: '16px', color: '#000000'}}>
                    I acknowledge and agree that BBC will generate shipments based upon reported wholesaler inventory and forecasted depletions, subject to change pending
                    transportation and product availability.
                </Typography>
            </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
            <Button variant="contained" onClick={handleAccept} autoFocus color="success" sx={{margin: '0px 12px 0px 0px'}}>Accept</Button>
            <Button variant="contained" onClick={handleClose} color="error" sx={{margin: '0px 0px 0px 12px'}}>Exit</Button>
        </DialogActions>
    </Dialog>);
}