import React from 'react'
import { Checkbox, TableCell, Typography } from "@mui/material"
import { useProductSettingsContext } from "../context/ProductSettingsContext";
import PropTypes from 'prop-types';

export const DivisionRow = ({ row }) => {
    const { onRowSelectClick, isRowSelected, isRowCheckboxIndeterminate } = useProductSettingsContext();
    return (<React.Fragment>
        <TableCell padding="checkbox" className="Division">
            <Checkbox
                id={row.id}
                color="primary"
                indeterminate={isRowCheckboxIndeterminate(row)}
                checked={isRowSelected(row)}
                onChange={event => onRowSelectClick(row, event.target.checked)}
            />
        </TableCell>
        <TableCell colSpan={7} className="Division">
            <Typography variant='h6'
                sx={{ marginLeft: '16px' }}>{row.id}</Typography>
        </TableCell>
    </React.Fragment>)
}

DivisionRow.propTypes = {
    row: PropTypes.object,
}