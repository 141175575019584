export function isAdmin(userData) {
    return userData?.roles?.includes("OB_Admin");
}

export function isSuperAdmin(userData) {
    return userData?.roles?.includes("OB_SuperAdmin");
}

export function isAnyAdmin(userData) {
    return isAdmin(userData) || isSuperAdmin(userData);
}

export function isNotAnyAdmin(userData) {
    return !isAdmin(userData) && !isSuperAdmin(userData);
}

export function isDistributor(userData) {
    return userData?.iUserType === 1;
}

export function isNotDistributor(userData) {
    return userData?.iUserType !== 1;
}