import { LicenseInfo } from '@mui/x-license-pro';
import React, { useState } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { FulfillmentStatusContextProvider } from "./Contexts/fulfillmentStatusContext";
import Layout from "./Layout";
import { ForecastReview } from "./Pages/ForecastReview/ForecastReview";
import { FulfillmentStatus } from "./Pages/FulfillmentStatus/FulfillmentStatus";
import ProductSettings from "./Pages/ProductSettings/ProductSettings";
import "./custom.css";
import { ProductSettingsContextProvider } from './Pages/ProductSettings/context/ProductSettingsContext';

LicenseInfo.setLicenseKey('ef023c2f771c3edb0c210801f27816ccTz02NDYzMyxFPTE3MTMzNTcwMTQzNzcsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Layout />}>
            <Route path="/forecasting" element={<ForecastReview />} />
            <Route path="/fulfillment"
                element={<FulfillmentStatusContextProvider><FulfillmentStatus /></FulfillmentStatusContextProvider>} />
            <Route path="/productSettings/:sku"
                element={<ProductSettingsContextProvider><ProductSettings /></ProductSettingsContextProvider>} />
            <Route path="*" element={<Navigate to="/forecasting" />} />
        </Route>
    ));

export default function App() {
    const [authenticated, setAuthenticated] = useState(false);
    const returnUrl = encodeURIComponent(window.location);

    fetch('login/isauthenticated?ReturnUrl=' + returnUrl).then((response) => {
        if (!response.ok) {
            window.location = 'login?ReturnUrl=' + returnUrl;
        } else {
            setAuthenticated(true)
        }
    });

    if (!authenticated) {
        return null;
    }

    return <RouterProvider router={router} />;
}