import { useEffect, useState } from "react";

export const useFetchProductSettingsDistributorOverrides = (sku) => {
    const [productSettingsDistributorsOverrides, setProductSettingsDistributorsOverrides] = useState([]);

    useEffect(() => {
        const fetchProductSettingsDistributorOverrides = async () => {
            const rsp = await fetch(`api/products/${sku}/distributors`);
            const productSettingsDistributorsOverrides = await rsp.json();

            setProductSettingsDistributorsOverrides(productSettingsDistributorsOverrides);
        }
        fetchProductSettingsDistributorOverrides();
    }, [sku])

    return productSettingsDistributorsOverrides;
}

export const PutProductSettingDistributorOverride = async (product, distributorRow) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const productSettingDistributorOverrideDto = {
        templateItemID: distributorRow.templateItemID,
        itemId: product.itemId,
        distributorId: distributorRow.id,
        palletQuantity: Number.parseInt(distributorRow.palletQuantity),
        palletDivisor: Number.parseInt(distributorRow.palletDivisor),
        unitWeight: Number.parseFloat(distributorRow.unitWeight)
    }

    // PUT request using fetch with error handling
    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(productSettingDistributorOverrideDto),
        signal: signal,
    };

    try {
        const response = await fetch(`api/products/productSettingsDistributorOverride`, requestOptions);
        if (!response.ok) {
            await response.json();
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const PutProductDistributorOfferingStatus = async (product, distributorRow) => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (distributorRow.skuOfferingStatus && distributorRow.skuOfferingStatus.id) {
        // PUT request using fetch with error handling
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(distributorRow.skuOfferingStatus.id),
            signal: signal,
        };

        try {
            const response = await fetch(`api/products/${product.sku}/${distributorRow.id}/offeringStatus`, requestOptions);
            if (!response.ok) {
                await response.json();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
}

export const PutProductDistributorFulfillmentType = async (product, distributorRow) => {
    const controller = new AbortController();
    const signal = controller.signal;

    // PUT request using fetch with error handling
    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(distributorRow.skuFulfillmentType.id),
        signal: signal,
    };

    try {
        const response = await fetch(`api/products/${product.sku}/${distributorRow.id}/fulfillmentType`, requestOptions);
        if (!response.ok) {
            await response.json();
        }
    } catch (error) {
        console.error('Error:', error);
    }
}