import React from "react";
import { Autocomplete, Skeleton, TextField, Checkbox } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { FilterSKUOfferingStatuses, useSKUOfferingStatusesContext } from "../Contexts/skuOfferingStatusesContext";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function SKUOfferingStatusMultiSelection() {
    const {
        skuOfferingStatuses,
        isReady: areSKUOfferingStatusesReady,
        selectedSKUOfferingStatuses,
        setSelectedSKUOfferingStatuses,
    } = useSKUOfferingStatusesContext();

    if (!areSKUOfferingStatusesReady) {
        return (
            <div>
                <Skeleton variant="rectangular" width={250} height={50} />{" "}
            </div>
        );
    }

    return (
        <div>
            {areSKUOfferingStatusesReady ? (
                <Autocomplete
                    multiple
                    limitTags={1}
                    disablePortal
                    filterOptions={(options, state) =>
                        FilterSKUOfferingStatuses(options, state.inputValue.toLocaleLowerCase())
                    }
                    id="combo-box-skuOfferingStatus"
                    getOptionLabel={(option) => `${option.description}`}
                    options={skuOfferingStatuses}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.id}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.description}
                        </li>
                    )}
                    sx={{ width: 250 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Offering Statuses" />
                    )}
                    value={selectedSKUOfferingStatuses || []}
                    onChange={(event, value) => {
                        setSelectedSKUOfferingStatuses(value);
                    }}
                />
            ) : (
                <Skeleton variant="rectangular" width={250} height={50} />
            )}
        </div>
    );
}