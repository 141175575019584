import {useEffect, useState} from "react";

export const useFetchProductSettings = (sku) => {
    const [productSettings, setProductSettings] = useState([]);

    useEffect(() => {
        const fetchProductSettings = async () => {
            const rsp = await fetch(`api/products/${sku}`);
            const productSettings = await rsp.json();

            setProductSettings(productSettings);
        }
        fetchProductSettings();
    }, [sku])

    return productSettings;
}