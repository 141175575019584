import React, { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, TableCell, TextField, Typography } from '@mui/material';
import { SkuFulfillmentTypeSelect } from "../components/SkuFulfillmentTypeSelect";
import { SkuOfferingStatusSelect } from '../components/SkuOfferingStatusSelect';
import { useProductSettingsContext } from "../context/ProductSettingsContext";
import PropTypes from 'prop-types';

export const DistributorTableRow = ({
    row,
    handleUpdateRow,
    onSaveProductSettingsDistributorOverride,
    skuFulfillmentTypesByCustomerType,
    isChanged
}) => {
    const { onRowSelectClick, isRowSelected } = useProductSettingsContext();
    const [originalRow, setOriginalRow] = useState(row);

    const onBlurHandler = async () => {
        await onSaveProductSettingsDistributorOverride(row, originalRow);
        await handleUpdateRow(row);
        setOriginalRow(row);
    };

    const onKeyPressHandler = async (event) => {
        if (event.key === "Enter") {
            await onSaveProductSettingsDistributorOverride(row, originalRow);
            setOriginalRow(row);
        }
    };

    return (<React.Fragment>
        <TableCell padding="checkbox">
            <Checkbox
                id={row?.id}
                color="primary"
                disabled={row?.legal === false}
                checked={isRowSelected(row)}
                onChange={event => onRowSelectClick(row, event.target.checked)} />
        </TableCell>
        <TableCell>
            <Typography
                sx={{ marginLeft: '64px' }}>{`${row?.sapId} - ${row?.name}`}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ padding: '0px' }}>
            {row?.legal === false ? (<CloseIcon />) : <></>}
        </TableCell>
        <TableCell align="center" sx={{ padding: '0px' }}>
            <TextField
                placeholder="Default"
                value={row.palletQuantity}
                size="small"
                type="number"
                disabled={row?.legal === false}
                onKeyPress={onKeyPressHandler}
                onChange={async (event) => {
                    if (!Number.isInteger(Number.parseFloat(event.target.value)) && event.target.value !== "") return;
                    await handleUpdateRow({ ...row, palletQuantity: event.target.value })
                }}
                onBlur={onBlurHandler}
            />
        </TableCell>
        <TableCell align="center" sx={{ padding: '0px' }}>
            <TextField
                placeholder="Default"
                value={row.palletDivisor}
                size="small"
                type="number"
                disabled={row?.legal === false}
                onKeyPress={onKeyPressHandler}
                onChange={async (event) => {
                    if (!Number.isInteger(Number.parseFloat(event.target.value)) && event.target.value !== "") return;
                    await handleUpdateRow({ ...row, palletDivisor: event.target.value })
                }}
                onBlur={onBlurHandler}
            />
        </TableCell>
        <TableCell align="center" sx={{ padding: '0px' }}>
            <TextField
                placeholder="Default"
                value={row.unitWeight}
                size="small"
                type="number"
                disabled={row?.legal === false}
                onKeyPress={onKeyPressHandler}
                onChange={async (event) => {
                    if (Number.isNaN(event.target.value) && event.target.value !== "") return;
                    await handleUpdateRow({ ...row, unitWeight: event.target.value })
                }}
                onBlur={onBlurHandler}
            />
        </TableCell>
        <TableCell align="center">
            <SkuOfferingStatusSelect
                isChanged={isChanged}
                row={row}
                originalRow={row}
                handleUpdateRow={handleUpdateRow}
                skuFulfillmentTypesByCustomerType={skuFulfillmentTypesByCustomerType} />
        </TableCell>
        <TableCell align="center">
            <SkuFulfillmentTypeSelect
                handleUpdateRow={handleUpdateRow}
                isChanged={isChanged}
                row={row}
                originalRow={row} />
        </TableCell>
    </React.Fragment>)
}

DistributorTableRow.propTypes = {
    row: PropTypes.object,
    handleUpdateRow: PropTypes.func,
    onSaveProductSettingsDistributorOverride: PropTypes.func,
    skuFulfillmentTypesByCustomerType: PropTypes.array,
    isChanged: PropTypes.func,
}