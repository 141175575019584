import React, {useContext, useEffect, useState} from "react";
import { datadogRum } from '@datadog/browser-rum';

const url = `userdata`;

export const UserDataContext = React.createContext(null);

export function UserDataContextProvider(props) {
    const [userData, setUserData] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                setError(data.error);
                setUserData(data);
                setIsReady(true);
                if (process.env.REACT_APP_DATADOG_ACTIVATE === 'true') {
                    console.log("Setting user session data for DataDog RUM...");
                    try {
                        datadogRum.setUser({ id: data.iUserId, name: data.chUsername });
                    } catch (e) {
                        console.error("Problem attempting to add user session info to DataDog", e);
                    }
                }
            });
    }, []);

    return (
        <UserDataContext.Provider
            value={{
                userData,
                isReady,
                error
            }}
        >
            {props.children}
        </UserDataContext.Provider>
    );
}

export function useUserDataContext() {
    const context = useContext(UserDataContext);

    if (!context)
        throw new Error(
            "useUserDataContext must be used within a UserDataContextProvider. Wrap a parent component in <UserDataContextProvider> to fix this error."
        );
    return context;
}