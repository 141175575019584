import React from "react";
import MenuItem from '@mui/material/MenuItem';
import { TextField } from "@mui/material";
import { PutProductDistributorOfferingStatus } from "../hooks/useFetchProductSettingsDistributorOverrides";
import { useSKUOfferingStatusesContext } from "../../../Contexts/skuOfferingStatusesContext";
import { useProductSettingsContext } from "../context/ProductSettingsContext";
import PropTypes from 'prop-types';
import { SkuOfferingStatus } from "../../../Enums/SkuOfferingStatus";

export const SkuOfferingStatusSelect = ({
    isChanged,
    row,
    originalRow,
    handleUpdateRow,
    skuFulfillmentTypesByCustomerType
}) => {
    const { skuOfferingStatuses } = useSKUOfferingStatusesContext();
    const { getPossibleSkuFulfillmentTypes } = useProductSettingsContext();

    const updateProductDistributorOfferingStatus = async (distributorRow, originalRow) => {
        if (isChanged(distributorRow, originalRow)) {
            await PutProductDistributorOfferingStatus(distributorRow.product, distributorRow);
        }
    };

    return (<TextField
        select
        value={row.skuOfferingStatus?.id ?? SkuOfferingStatus.REMOVED}
        size="small"
        fullWidth
        disabled={row.legal === false}
        onChange={async (event) => {
            let fulfillmentType;
            let tempOverride = skuOfferingStatuses.find(status => status.id === event.target.value)?.skuFulfillmentTypeOverride;

            if (tempOverride) {
                fulfillmentType = skuFulfillmentTypesByCustomerType.find(type => type.customerType === 0).skuFulfillmentTypes.find(type => type.id === tempOverride);
            }

            const tempRow = {
                ...row,
                skuOfferingStatus: skuOfferingStatuses.find(status => status.id === event.target.value),
                skuFulfillmentType: fulfillmentType ?? await getPossibleSkuFulfillmentTypes(tempOverride, row.type).skuFulfillmentTypes[0]
            };

            await updateProductDistributorOfferingStatus(tempRow, originalRow);
            await handleUpdateRow(tempRow);
        }}
    >
        {skuOfferingStatuses?.map(status => <MenuItem key={status.id}
            value={status.id}>
            {status.description}
        </MenuItem>)}
    </TextField>

    );
}

SkuOfferingStatusSelect.propTypes = {
    handleUpdateRow: PropTypes.func,
    isChanged: PropTypes.func,
    row: PropTypes.object,
    originalRow: PropTypes.object,
    skuFulfillmentTypesByCustomerType: PropTypes.array
}