import {useEffect, useState} from "react";

export const useFetchProductFulfillment = (sku) => {
    const [productFulfillment, setProductFulfillment] = useState([]);

    useEffect(() => {
        const fetchProductFulfillment = async () => {
            const rsp = await fetch(`api/fulfillmentStatus/${sku}`);
            const productFulfillment = await rsp.json();

            setProductFulfillment(productFulfillment);
        }
        fetchProductFulfillment();
    }, [sku])

    return productFulfillment;
}