import {Grid, Paper} from "@mui/material";
import footer from "../Images/footer_02_b.gif";
import footerBar from "../Images/footer_02_a.gif";
import React from "react";

export default function Footer() {
    return (
        <Paper
            sx={{
                width: "100%",
                position: "relative",
                bottom: 0,
            }}
            component="footer"
            square
            variant="outlined"
        >
            <Grid container columns={5} alignItems="center">
                <Grid item xs={5}>
                    <img src={footerBar} alt="footerBar" width={"100%"} height={7}/>
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={3} textAlign="center">
                    <img src={footer} alt="footer" align="center"/>
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        </Paper>
    );
}