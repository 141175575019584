import React, {useContext, useEffect, useState} from "react";
import {useDistributorsContext} from "./distributorsContext";
import {useUserDataContext} from "./userdataContext";

export const AppContext = React.createContext(null);

export function AppContextProvider(props) {
    const {distributors, isReady: areDistributorsReady} =
        useDistributorsContext();
    const {userData} = useUserDataContext();

    const [selectedDistributor, setSelectedDistributor] = useState();
    const [selectedDistributors, setSelectedDistributors] = useState();

    useEffect(() => {
        if (areDistributorsReady && distributors.length === 1) {
            setSelectedDistributor(distributors[0]);            
        }
        setSelectedDistributors(distributors);
    }, [distributors, areDistributorsReady]);

    return (
        <AppContext.Provider
            value={{
                distributors,
                areDistributorsReady,
                selectedDistributor,
                setSelectedDistributor,
                selectedDistributors,
                setSelectedDistributors,
                userData
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export function useAppContext() {
    const context = useContext(AppContext);

    if (!context)
        throw new Error(
            "useAppContext must be used within a AppContextProvider. Wrap a parent component in <AppContextProvider> to fix this error."
        );
    return context;
}