import React, { useEffect, useState } from "react";
import { Button, Skeleton, Grid } from "@mui/material";
import ForecastReviewGrid from "../../Components/ForecastReviewGrid";
import ProductMultiSelection from "../../Components/ProductMultiSelection";
import { useForecastReviewContext } from "../../Contexts/forecastReviewContext";
import { useDistributorsContext } from "../../Contexts/distributorsContext";
import { CustomerType } from "../../Enums/CustomerType";
import { useProductsContext } from "../../Contexts/productsContext";
import DistributorMultiselection from "../../Components/DistributorMultiSelection";

export const ForecastReview = () => {
  const { selectedDistributors } = useDistributorsContext();
  const { selectedProducts, setSelectedProducts } = useProductsContext();
  const { isReady: areForecastReviewItemsReady, forecastReviewItems, getFormattedOriginalForecastReviewItems, setForecastReviewItems, get } = useForecastReviewContext();
  const [exporting, setExporting] = useState(false);

  const exportForecast = () => {
    setExporting(true);

    let distributors = "";
    selectedDistributors.forEach(distributor => distributors += `distributorIds=${distributor.distributorId}&`);

    let products = "";
    selectedProducts.forEach(product => products += `productSKUs=${product.sku}&`);

    window.open(`api/Distributor/Forecasts/csvFile?${distributors}${products}`);

    setExporting(false);
  };

  useEffect(() => {
    if (forecastReviewItems?.length === 0) {
      const selectedSKUs = selectedProducts?.map(selectedProduct => selectedProduct.sku);
      setForecastReviewItems(getFormattedOriginalForecastReviewItems().filter(forecastReviewItem => selectedSKUs.length > 0 ? selectedSKUs.includes(forecastReviewItem.product.sku) : true));
    }
  }, [selectedProducts, setSelectedProducts]);

  return (
    <React.Fragment>
      <Grid container columnSpacing={2} padding={2} justifyContent="end" alignItems="center">
        <Grid item xs={4}>
          <DistributorMultiselection
            customerType={CustomerType.VMI}
            filterByCanOrderBeer={true}
            onClose={() => get()}
          />
        </Grid>
        <Grid item xs={4}>
          <ProductMultiSelection />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            disabled={forecastReviewItems.length === 0 && !exporting}
            onClick={exportForecast}
            style={{ marginLeft: "80%" }}
          >
            Export
          </Button>
        </Grid>
      </Grid>

      {!selectedDistributors || !areForecastReviewItemsReady ? (
        selectedDistributors && !areForecastReviewItemsReady ? (
          <Skeleton variant="rectangular" height={400} />
        ) : (
          <center style={{ padding: 25 }}>
            <h4>Please select a distributor to review their forecast</h4>
          </center>
        )
      ) : forecastReviewItems.length === 0 ? (
        <center style={{ padding: 25 }}>
          <h4>There is no forecast data for the selected filter</h4>
          <h5>
            If you believe this is an error, please contact your business
            partner or the helpdesk at
            <a href="mailto: help@bostonbeer.com"> help@bostonbeer.com</a>
          </h5>
        </center>
      ) : (
        <ForecastReviewGrid />
      )}
    </React.Fragment >
  );
};