const OboDomainPrefix = "https://obo";
const OboDomain = ".bostonbeer.com";

const directoryDictionary = [
    {
        "id": "AdminDashboard",
        "label": "Dashboard",
        "path": "/administration/dashboard/dashboard.aspx"
    },
    {
        "id": "AdminWindowManagement",
        "label": "Window Mgmt.",
        "path": "/administration/Template/weekly_edit.aspx"
    },
    {
        "id": "AdminProductSettings",
        "label": "Product Settings",
        "path": "/administration/Template/master_edit.aspx"
    },
    {
        "id": "AdminProductAvailability",
        "label": "Product Availability",
        "path": "/administration/Item/availability_rollup.aspx"
    },
    {
        "id": "AdminDistributorManagement",
        "label": "Distributor Mgmt.",
        "path": "/administration/Distributor/default.aspx"
    },
    {
        "id": "AdminShipmentsManagement",
        "label": "Shipments Mgmt.",
        "path": "/administration/Distributor/order_rollup.aspx"
    },
    {
        "id": "AdminMessageManagement",
        "label": "Message Mgmt.",
        "path": "/administration/Messaging/message_search.aspx"
    },
    {
        "id": "AdminFeedSenderManagement",
        "label": "Feed Sender Mgmt.",
        "path": "/administration/FeedSender/default.aspx"
    },
    {
        "id": "AdminUserDistributorMap",
        "label": "User Distributor Map",
        "path": "/administration/Distributor/distributor_user_map.aspx"
    },
    {
        "id": "AdminFulfillmentStatus",
        "label": "Fulfillment Status",
        "path": "/fulfillment"
    },
    {
        "id": "AdminAdHocWindowManagement",
        "label": "Ad Hoc Window Mgmt.",
        "path": "/administration/Window/default.aspx"
    },
    {
        "id": "AdminItemManagement",
        "label": "Item Mgmt.",
        "path": "/administration/Item/item_search.aspx"
    },

    {
        "id": "ForecastReview",
        "label": "Forecast Review",
        "path": "/forecastReview"
    },
    {
        "id": "ForecastAdjustments",
        "label": "Forecast Adjustments",
        "path": "/FBP/forecast_adjustment_report.aspx"
    },

    {
        "id": "PlanningSubmissionReport",
        "label": "Submission Report",
        "path": "/Planning/submission_report.aspx"
    },
    {
        "id": "PlanningComparisonReport",
        "label": "Comparison Report",
        "path": "/Planning/comparison_report.aspx"
    },
    {
        "id": "PlanningRollupReport",
        "label": "Rollup Report",
        "path": "/Planning/rollup_report.aspx"
    },
    {
        "id": "PlanningWindowManagement",
        "label": "Window Mgmt.",
        "path": "/Administration/Planning/window.aspx"
    },
    {
        "id": "PlanningDepletionImport",
        "label": "Depletion Import",
        "path": "/Administration/Planning/depletion_import.aspx"
    },
    {
        "id": "PlanningPlanningPeriods",
        "label": "Planning Periods",
        "path": "/Administration/Planning/period_edit.aspx"
    },
    {
        "id": "PlanningWindowCoworker",
        "label": "Planning Window",
        "path": "/planning/default.aspx?allocationwindowid="
    },
    {
        "id": "PlanningWindowDistributor",
        "label": "Planning Window",
        "path": "/planning/distributor.aspx?allocationwindowid="
    }
]

export function GetOboLink(id) {
    let environment;
    if (process.env.REACT_APP_ENV === "local")
        environment = "dev"
    else if (process.env.REACT_APP_ENV === "production")
        environment = "";
    else
        environment = process.env.REACT_APP_ENV;

    const entry = directoryDictionary.find((entry) => entry.id === id);
    if (entry) {
        const newPath = OboDomainPrefix + environment + OboDomain + entry.path;
        return {...entry, "path": newPath}
    }
    return null;
}

export function GetObo2Link(id) {
    return directoryDictionary.find((entry) => entry.id === id);
}