import * as React from "react";
import {useState} from "react";
import {DataGridPremium, useGridApiRef} from "@mui/x-data-grid-premium";
import {useFulfillmentStatusContext} from "../Contexts/fulfillmentStatusContext";
import {Box, Button} from "@mui/material";
import {Alert} from "@mui/lab";

export default function FulfillmentStatusGrid() {
    const {
        fulfillmentStatusItems, isReady: areFulfillmentStatusItemsReady,
    } = useFulfillmentStatusContext();
    const [error, setError] = useState(false);
    const [edited, setEdited] = useState(false);
    const [sending, setSending] = useState(false);

    let columnDefinitions = [];
    const apiRef = useGridApiRef();      

    if (areFulfillmentStatusItemsReady && fulfillmentStatusItems.length > 0) {
        columnDefinitions = gridColumns();
    }

    return (<div>
        {error ? (<Alert
            variant="filled"
            severity="error"
            action={<Button
                color="inherit"
                size="small"
                onClick={() => window.location.reload()}
            >
                Refresh Page
            </Button>}
        >
            Search has failed! Refresh the page before trying again.
        </Alert>) : (<Box
            sx={{
                height: 'calc(100vh - 300px)', width: "100%", padding: 2, "& .adjustable": {
                    backgroundColor: "#d2f7d4", color: "#000000",
                }, "& .adjusted": {
                    backgroundColor: "#c1f7ec", color: "#000000",
                }, "& .banded": {
                    backgroundColor: "#a9dbfc", color: "#000000",
                }, "& .locked": {
                    backgroundColor: "#EBEBE4", color: "#000000",
                },
            }}
        >
            <DataGridPremium
                rows={fulfillmentStatusItems}
                columns={columnDefinitions}
                apiRef={apiRef}
                loading={error || !areFulfillmentStatusItemsReady || sending}
                disableColumnMenu               
                getRowId={(row) => row.distributor.distributorId + '_' + row.product.itemId}
                rowHeight={30}
                columnHeaderHeight={30}
                onCellEditStop={async () => setEdited(true)}     
                pagination
                autoPageSize
            />            
        </Box>)}
    </div>);
}

//#region ColumnSetup
const gridColumns = () => {
    return [{
        field: "distributor.division",
        headerName: "Division",
        headerClassName: 'bg-info',
        width: 100,
        valueGetter: (params) => params.row.distributor?.division,
    }, {
        field: "distributor.region",
        headerName: "Region",
        headerClassName: 'bg-info',
        width: 150,
        valueGetter: (params) => params.row.distributor?.region,
    }, {
        field: "distributor.name",
        headerName: "Distributor",
        headerClassName: 'bg-info',
        width: 300,
        valueGetter: (params) => params.row.distributor?.name,
    }, {
        field: "distributor.sapId",
        headerName: "Distributor SAP ID",
        headerClassName: 'bg-info',
        width: 100,
    valueGetter: (params) => params.row.distributor?.sapId,
    }, {
        field: "product.description",
        headerName: "SKU Name",
        headerClassName: 'bg-info',
        width: 300,
        valueGetter: (params) => params.row.product?.description,
    }, {
        field: "product.sku",
        headerName: "BBC SKU",
        headerClassName: 'bg-info',
        width: 100,
        valueGetter: (params) => params.row.product?.sku,
    }, {
        field: "availability",
        headerName: "Availability",
        headerClassName: 'bg-info',
        width: 100,
        valueGetter: (params) => params.row?.availability,
    }, {
        field: "skuOfferingStatus.description",
        headerName: "Offering Status",
        headerClassName: 'bg-info',
        width: 100,
        valueGetter: (params) => params.row.skuOfferingStatus?.description,
    }, {
        field: "skuFulfillmentType.description",
        headerName: "Fulfillment Method",
        headerClassName: 'bg-info',
        width: 100,
        valueGetter: (params) => params.row.skuFulfillmentType?.description,
    }, {
        field: "indirectDistributorName",
        headerName: "Source Distributor",
        headerClassName: 'bg-info',
        width: 300,
        valueGetter: (params) => params.row.indirectDistributorName,
    }, {
        field: "indirectDistributorSapId",
        headerName: "Source Distributor SAP ID",
        headerClassName: 'bg-info',
        flex: 100,
        valueGetter: (params) => params.row.indirectDistributorSapId,
    },];
};

//#endregion