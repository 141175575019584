import React, {useContext, useEffect, useState} from "react";

const url = `api/products?`;

export const ProductsContext = React.createContext(null);

export function ProductsContextProvider(props) {
    const [products, setProducts] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedAvailability, setSelectedAvailability] = useState(true);
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        fetch(url + 'onlyActiveProducts=false')
            .then((res) => res.json())
            .then((data) => {
                setError(data.error);
                setProducts(data);
                setIsReady(true);
            });
    }, []);

    return (
        <ProductsContext.Provider
            value={{
                products,
                selectedProducts,
                setSelectedProducts,
                selectedAvailability,
                setSelectedAvailability,
                isReady,
                error,
            }}
        >
            {props.children}
        </ProductsContext.Provider>
    );
}

export function useProductsContext() {
    const context = useContext(ProductsContext);

    if (!context)
        throw new Error(
            "useProductsContext must be used within a ProductsContextProvider. Wrap a parent component in <ProductsContextProvider> to fix this error."
        );
    return context;
}

export function FilterProducts(products, searchString) {
    return products.filter(
        (product) =>
            product.sku.toLowerCase().includes(searchString) ||
            product.description.toLowerCase().includes(searchString)
    );
}