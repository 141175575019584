import React, {useContext, useEffect, useState} from "react";

const url = `api/distributors?`;

export const DistributorsContext = React.createContext(null);

export function DistributorsContextProvider(props) {
  const [distributors, setDistributors] = useState(null);
  const [selectedDistributor, setSelectedDistributor] = useState();
  const [selectedDistributors, setSelectedDistributors] = useState();
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setError(data.error);
        setDistributors(TrimDistributorNames(data));
        setIsReady(true);
      });
  }, []);

  return (
    <DistributorsContext.Provider
      value={{
        distributors,
        selectedDistributor,
        setSelectedDistributor,
        selectedDistributors,
        setSelectedDistributors,
        isReady,
        error,
      }}
    >
      {props.children}
    </DistributorsContext.Provider>
  );
}

export function useDistributorsContext() {
  const context = useContext(DistributorsContext);

  if (!context)
    throw new Error(
      "useDistributorsContext must be used within a DistributorsContextProvider. Wrap a parent component in <DistributorsContextProvider> to fix this error.",
    );
  return context;
}

export function FilterDistributors(distributors, searchString) {
  return distributors.filter(
    (distributor) =>
      distributor.name.toLowerCase().includes(searchString) ||
      distributor.sapId.toLowerCase().includes(searchString),
  );
}

function TrimDistributorNames(distributors) {
  return distributors.map((distributor) => ({
    ...distributor,
    name: distributor.name.trim(),
  }));
}