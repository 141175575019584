import React from "react";
import ReactDOM from "react-dom/client";
import "./custom.css";
import reportWebVitals from "./reportWebVitals";
import Root from "./Root";
import {AppContextProvider} from "./Contexts/AppContext";
import {UserDataContextProvider} from "./Contexts/userdataContext";
import {PlanningWindowContextProvider} from "./Contexts/planningWindowsContext";
import {DistributorsContextProvider} from "./Contexts/distributorsContext";
import {ProductsContextProvider} from "./Contexts/productsContext";
import {ForecastReviewContextProvider} from "./Contexts/forecastReviewContext";
import {SKUOfferingStatusesContextProvider} from "./Contexts/skuOfferingStatusesContext";
import {SKUFulfillmentTypesContextProvider} from "./Contexts/skuFulfillmentTypesContext";

import { datadogRum } from '@datadog/browser-rum';

if (process.env.REACT_APP_DATADOG_ACTIVATE === 'true') {
    console.log("Initializing DataDog RUM...")
    try {
        datadogRum.init({
            applicationId: process.env.REACT_APP_DATADOG_APPLICATIONID,
            clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
            site: 'datadoghq.com',
            service: 'onlinebeer',
            env: process.env.REACT_APP_DATADOG_ENV,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            trackSessionAcrossSubdomains: true,
            allowedTracingUrls: [/https:\/\/.*\.bostonbeer\.com/, /https:\/\/.*localhost.*/]
        });
    } catch (e) {
        console.error("Datadog failed to initialize", e);
    }
} else {
    console.log("DataDog RUM is not configured for this environment");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<UserDataContextProvider>
    <PlanningWindowContextProvider>
        <ProductsContextProvider>
            <SKUOfferingStatusesContextProvider>
                <SKUFulfillmentTypesContextProvider>
                    <DistributorsContextProvider>
                        <ForecastReviewContextProvider>
                            <AppContextProvider>
                                <Root/>
                            </AppContextProvider>
                        </ForecastReviewContextProvider>
                    </DistributorsContextProvider>
                </SKUFulfillmentTypesContextProvider>
            </SKUOfferingStatusesContextProvider>
        </ProductsContextProvider>
    </PlanningWindowContextProvider>
</UserDataContextProvider>);

reportWebVitals();