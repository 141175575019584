import React from "react";
import {Grid, Paper, Typography} from "@mui/material";
import BBCLogo from "../Images/BBClogo.svg";
import {BackgroundImage} from "../Contexts/BackgroundImageProvider";

const styles = {
    paperContainer: {
        backgroundImage: `url(${BackgroundImage()})`
    }
}

export default function Header() {
    return (
        <Paper style={styles.paperContainer}>
            <Grid container columns={5} alignItems="center">
                <Grid item xs={1}>
                    <img src={BBCLogo} alt="Boston Beer Company" align="center"/>
                </Grid>
                <Grid item xs={3} textAlign="center">
                    <Typography variant="h3" style={{fontSize: "36px", fontWeight: "500", fontFamily: "Verdana, sans-serif", color: "#0A2447"}}>Online Beer Ordering</Typography>
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        </Paper>
    );
}