import React, { useEffect, useState } from "react";
import { Autocomplete, Skeleton, TextField, Checkbox } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  FilterDistributors,
  useDistributorsContext,
} from "../Contexts/distributorsContext";
import PropTypes from 'prop-types';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function DistributorMultiselection({
  customerType,
  filterByCanOrderBeer,
  onClose
}) {
  const {
    distributors,
    isReady: areDistributorsReady,
    selectedDistributors,
    setSelectedDistributors,
  } = useDistributorsContext();
  const [filteredDistributors, setFilteredDistributors] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (areDistributorsReady) {
      let filteredDistributorsTemp = distributors;

      if (customerType) {
        filteredDistributorsTemp = filteredDistributorsTemp.filter(
          (distributor) => distributor.type === customerType,
        );
      }
      if (filterByCanOrderBeer) {
        filteredDistributorsTemp = filteredDistributorsTemp.filter(
          (distributor) => distributor.canOrderBeer,
        );
      }

      setFilteredDistributors(filteredDistributorsTemp);
    }
  }, [areDistributorsReady]);

  return (
    <div>
      {areDistributorsReady ? (
        <Autocomplete
          multiple
          limitTags={1}
          disablePortal
          filterOptions={(options, state) =>
            FilterDistributors(options, state.inputValue.toLocaleLowerCase())
          }
          id="combo-box-distributor"
          getOptionLabel={(option) => `${option.sapId} - ${option.name}`}
          options={filteredDistributors}
          disableCloseOnSelect
          inputValue={inputValue}
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setInputValue('');
            } else if (reason !== 'reset') {
              setInputValue(value);
            }
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.sapId}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.sapId} - {option.name}
            </li>
          )}
          onClose={() => onClose && onClose()}
          sx={{ width: 350 }}
          renderInput={(params) => (
            <TextField {...params} label="Select Distributors" />
          )}
          value={selectedDistributors || []}
          onChange={(event, value) => {
            setSelectedDistributors(value);
          }}
        />
      ) : (
        <Skeleton variant="rectangular" width={350} height={50} />
      )}
    </div>
  );
}

DistributorMultiselection.propTypes = {
  customerType: PropTypes.number,
  filterByCanOrderBeer: PropTypes.bool,
  onClose: PropTypes.func
}