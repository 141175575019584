import React from 'react'
import {Checkbox, TableCell, Typography} from "@mui/material"
import {useProductSettingsContext} from "../context/ProductSettingsContext";
import PropTypes from 'prop-types';

export const DistrictRow = ({ row }) => {
    const {onRowSelectClick, isRowSelected, isRowCheckboxIndeterminate} = useProductSettingsContext();

    return (<React.Fragment>
        <TableCell padding="checkbox" className="District">
            <Checkbox
                id={row.id}
                color="primary"
                indeterminate={isRowCheckboxIndeterminate(row)}
                checked={isRowSelected(row)}
                onChange={event => onRowSelectClick(row, event.target.checked)}/>
        </TableCell>
        <TableCell colSpan={7} className="District">
            <Typography
                sx={{ marginLeft: '48px' }}>{row.id}</Typography>
        </TableCell>
    </React.Fragment>)
}

DistrictRow.propTypes = {
    row: PropTypes.object,
}