import React from "react";
import {Box, Paper, ThemeProvider} from "@mui/material";
import {theme} from "./Components/theme";
import Footer from "./Components/Footer";
import NavBar from "./Components/NavBar"
import Header from "./Components/Header";
import {Outlet} from "react-router-dom";
import ModalNotificationsPopup from "./Components/ModalNotificationsPopup";


export default function Layout() {
    return (<Box id={"box"}>
        <ThemeProvider theme={theme}>
            <Header/>
            <NavBar/>
            <Paper id={"remaining"}>
                <ModalNotificationsPopup/>
                <Outlet/>
            </Paper>
            <Footer/>
        </ThemeProvider>
    </Box>);
}