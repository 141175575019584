import { TextField } from "@mui/material";
import React from "react";
import { PutProductDistributorFulfillmentType } from "../hooks/useFetchProductSettingsDistributorOverrides";
import MenuItem from '@mui/material/MenuItem';
import { useProductSettingsContext } from "../context/ProductSettingsContext";
import PropTypes from 'prop-types';
import { SkuOfferingStatus } from "../../../Enums/SkuOfferingStatus";

export const SkuFulfillmentTypeSelect = ({
    handleUpdateRow, isChanged, row, originalRow
}) => {
    const { getPossibleSkuFulfillmentTypes } = useProductSettingsContext();

    const updateProductDistributorFulfillmentType = async (distributorRow, originalRow) => {
        if (isChanged(distributorRow, originalRow)) {
            await PutProductDistributorFulfillmentType(distributorRow.product, distributorRow);
        }
    };

    return <TextField
        id="outlined-select-fulfillment"
        select
        value={!row.skuOfferingStatus || row.skuOfferingStatus?.id === SkuOfferingStatus.REMOVED ? SkuOfferingStatus.REMOVED : row.skuFulfillmentType?.id}
        size="small"
        fullWidth
        disabled={row.legal === false || row.skuOfferingStatus?.id !== SkuOfferingStatus.INDIRECT}
        onChange={async (event) => {
            const tempRow = {
                ...row,
                skuFulfillmentType: getPossibleSkuFulfillmentTypes(row.skuOfferingStatus?.skuFulfillmentTypeOverride, row.type)?.skuFulfillmentTypes.find(status => status.id === event.target.value)
            }

            await handleUpdateRow(tempRow)
            await updateProductDistributorFulfillmentType(tempRow, originalRow);
        }}
    >
        {getPossibleSkuFulfillmentTypes(row.skuOfferingStatus?.skuFulfillmentTypeOverride, row.type)?.skuFulfillmentTypes?.map(status =>
            <MenuItem key={status.id}
                value={status.id}>
                {status.description}
            </MenuItem>)}
    </TextField>
}

SkuFulfillmentTypeSelect.propTypes = {
    handleUpdateRow: PropTypes.func,
    isChanged: PropTypes.func,
    row: PropTypes.object,
    originalRow: PropTypes.object,
}