import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useProductsContext } from "../Contexts/productsContext";
import PropTypes from 'prop-types';

SelectAvailability.propTypes = {
    minWidth: PropTypes.number
}

export default function SelectAvailability({
    minWidth = 155
}) {
    const {
        availability,
        setSelectedAvailability,
    } = useProductsContext();

    const handleChange = (event) => {
        setSelectedAvailability(event.target.value);
    };

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: minWidth }}>
                <InputLabel>Availability</InputLabel>
                <Select
                    value={availability}
                    defaultValue={true}
                    label="Availability"
                    onChange={handleChange}
                >
                    <MenuItem>
                        <em>ALL</em>
                    </MenuItem>
                    <MenuItem value={true}>Available</MenuItem>
                    <MenuItem value={false}>Unavailable</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}