import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Checkbox, Skeleton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FilterProducts, useProductsContext } from "../Contexts/productsContext";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function ProductMultiSelection() {
    const {
        products,
        isReady,
        selectedProducts,
        setSelectedProducts,
    } = useProductsContext();
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setFilteredProducts(products ? products : []);
    }, [isReady, products]);

    return (
        <React.Fragment>
            {isReady ? (
                <Autocomplete
                    multiple
                    limitTags={1}
                    disablePortal
                    filterOptions={(options, state) => FilterProducts(options, state.inputValue.toLocaleLowerCase())}
                    id="combo-box-product"
                    getOptionLabel={(option) => `${option.sku} - ${option.description}`}
                    options={filteredProducts}
                    disableCloseOnSelect
                    inputValue={inputValue}
                    onInputChange={(event, value, reason) => {
                        if (event && event.type === 'blur') {
                            setInputValue('');
                        } else if (reason !== 'reset') {
                            setInputValue(value);
                        }
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.sku}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.sku} - {option.description}
                        </li>
                    )}
                    sx={{ width: 400 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Products" />
                    )}
                    value={selectedProducts}
                    onChange={(event, value) => {
                        event.preventDefault();
                        setSelectedProducts([...value]);
                    }}
                />
            ) : (
                <Skeleton variant="rectangular" width={350} height={50} />
            )}
        </React.Fragment>
    );
}