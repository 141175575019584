import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useFetchProductSettings } from "../hooks/useFetchProductSettings";
import { useFetchProductSettingsDistributorOverrides } from "../hooks/useFetchProductSettingsDistributorOverrides";
import { useDistributorsContext } from "../../../Contexts/distributorsContext";
import { useFetchProductFulfillment } from "../hooks/useFetchProductFulfillment";
import { useFetchSkuFulfillmentTypesByCustomerType } from "../hooks/useFetchSkuFulfillmentTypesByCustomerType";
import PropTypes from 'prop-types';
import { DISTRIBUTOR_LIST_OPTIONS } from '../../../Enums/DistributorListOptions';

export const ProductSettingsContext = React.createContext(null);

ProductSettingsContextProvider.propTypes = {
    children: PropTypes.node,
}

export function ProductSettingsContextProvider({ children }) {
    const { sku } = useParams();
    const { distributors, isReady } = useDistributorsContext();
    const productDefaults = useFetchProductSettings(sku);
    const productSettingsDistributorOverrides = useFetchProductSettingsDistributorOverrides(sku);
    const productFulfillment = useFetchProductFulfillment(sku);
    const skuFulfillmentTypesByCustomerType = useFetchSkuFulfillmentTypesByCustomerType();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [unfilteredProductSettingsDistributorTable, setUnfilteredProductSettingsDistributorTable] = useState([]);
    const [productSettingsDistributorTable, setProductSettingsDistributorTable] = useState();
    const [distributorListSelectedOption, setDistributorListSelectedOption] = useState(DISTRIBUTOR_LIST_OPTIONS[1].value);
    const [searchString, setSearchString] = useState("");
    // rows with selected checkbox
    const [selected, setSelected] = useState(new Set());

    const getPossibleSkuFulfillmentTypes = (skuFulfillmentTypeOverride, customerType) => {
        let possibleFulfillmentTypes = { ...skuFulfillmentTypesByCustomerType && skuFulfillmentTypesByCustomerType.find(type => type.customerType === customerType) };
        if (skuFulfillmentTypeOverride) {
            possibleFulfillmentTypes.skuFulfillmentTypes = skuFulfillmentTypesByCustomerType.find(type => type.customerType === 0).skuFulfillmentTypes.filter(fulfillmentType => fulfillmentType.id === skuFulfillmentTypeOverride);
        }
        return possibleFulfillmentTypes;
    };

    const isRowCheckboxIndeterminate = (productSettingsRow) => {
        switch (productSettingsRow.kind.value) {
            case 0:
                return productSettingsDistributorTable.filter(row => selected.has(row.id)).some(row => row.division === productSettingsRow.id) && !selected.has(productSettingsRow?.id)
            case 1:
                return productSettingsDistributorTable.filter(row => selected.has(row.id)).some(row => row.region === productSettingsRow.id) && !selected.has(productSettingsRow?.id)
            case 2:
                return productSettingsDistributorTable.filter(row => selected.has(row.id)).some(row => row.district === productSettingsRow.id) && !selected.has(productSettingsRow?.id)
            default:
                return false;
        }
    }

    const isRowSelected = (productSettingsRow) => {
        return selected.has(productSettingsRow?.id) ?? false
    }

    const onRowSelectClick = (productSettingsRow, isChecked) => {
        let divisionRowIds = productSettingsDistributorTable.filter(row => (row.division === productSettingsRow.id || (row.division && row.division === productSettingsRow.division)) && row.legal !== false).map(row => row.id);
        let regionRowIds = productSettingsDistributorTable.filter(row => (row.region === productSettingsRow.id || (row.region && row.region === productSettingsRow.region)) && row.legal !== false).map(row => row.id);
        let districtRowIds = productSettingsDistributorTable.filter(row => (row.district === productSettingsRow.id || (row.district && row.district === productSettingsRow.district)) && row.legal !== false).map(row => row.id);

        switch (productSettingsRow.kind.value) {
            case 0: // division
                if (isChecked) {
                    let updated = new Set([...selected, productSettingsRow.id, ...divisionRowIds]);
                    setSelected(updated);
                } else {
                    selected.delete(productSettingsRow.id);
                    for (let index = 0; index < divisionRowIds.length; index++) {
                        selected.delete(divisionRowIds[index]);
                    }

                    for (let index = 0; index < regionRowIds.length; index++) {
                        selected.delete(regionRowIds[index]);
                    }

                    for (let index = 0; index < districtRowIds.length; index++) {
                        selected.delete(districtRowIds[index]);
                    }
                    setSelected(new Set([...selected]));
                }
                break;
            case 1: //region
                if (isChecked) {
                    let updated = new Set([...selected, productSettingsRow.id, ...regionRowIds]);

                    if (divisionRowIds.every(item => updated.has(item))) {
                        updated = updated.add(productSettingsRow.division);
                    }
                    setSelected(updated);
                } else {
                    selected.delete(productSettingsRow.id);
                    for (let index = 0; index < regionRowIds.length; index++) {
                        selected.delete(regionRowIds[index]);
                    }

                    for (let index = 0; index < districtRowIds.length; index++) {
                        selected.delete(districtRowIds[index]);
                    }
                    setSelected(new Set([...selected]));
                }
                break;
            case 2: //district
                if (isChecked) {
                    let updated = new Set([...selected, productSettingsRow.id, ...districtRowIds]);

                    if (regionRowIds.every(item => updated.has(item))) {
                        updated = updated.add(productSettingsRow.region);
                    }
                    if (divisionRowIds.every(item => updated.has(item))) {
                        updated = updated.add(productSettingsRow.division);
                    }
                    setSelected(updated);
                } else {
                    selected.delete(productSettingsRow.id);
                    for (let index = 0; index < districtRowIds.length; index++) {
                        selected.delete(districtRowIds[index]);
                    }
                    setSelected(new Set([...selected]));
                }
                break;
            default:
                if (isChecked) {
                    let updated = new Set([...selected, productSettingsRow.id]);

                    if (districtRowIds.every(item => updated.has(item))) {
                        updated = updated.add(productSettingsRow.district);
                    }
                    if (regionRowIds.every(item => updated.has(item))) {
                        updated = updated.add(productSettingsRow.region);
                    }
                    if (divisionRowIds.every(item => updated.has(item))) {
                        updated = updated.add(productSettingsRow.division);
                    }
                    setSelected(updated);
                } else {
                    selected.delete(productSettingsRow.id);
                    setSelected(new Set([...selected]));
                }
        }

    }

    const updateProductSettingsDistributorTable = async (updatedRows) => {
        const updatedTable = unfilteredProductSettingsDistributorTable.map((row) => {
            return updatedRows.find(updated => updated.id === row.id) ?? row;
        })

        await setUnfilteredProductSettingsDistributorTable(updatedTable);
    }

    const isChanged = (newRow, oldRow) => {
        return (oldRow.palletQuantity !== newRow.palletQuantity || oldRow.palletDivisor !== newRow.palletDivisor || oldRow.unitWeight !== newRow.unitWeight || oldRow.skuOfferingStatus?.id !== newRow.skuOfferingStatus?.id || oldRow.skuFulfillmentType?.id !== newRow.skuFulfillmentType?.id)
    };

    const loadDistributorTableSettings = (distributors, productSettingsDistributorOverrides, productFulfillment) => {
        if (Object.keys(productDefaults).length === 0 || Object.keys(productSettingsDistributorOverrides).length === 0 || Object.keys(productFulfillment).length === 0 || !isReady) {
            return null;
        }

        return distributors.map(distributor => {
            let override = productSettingsDistributorOverrides.find(override => override.distributorId === distributor.distributorId)
            return {
                id: distributor.distributorId.toString(), ...distributor,
                legal: override?.legal ?? true,
                palletQuantity: override?.palletQuantity,
                palletDivisor: override?.palletDivisor,
                unitWeight: override?.unitWeight, 
                ...productFulfillment.find(fulfillment => fulfillment.distributor.distributorId === distributor.distributorId),
                product: productFulfillment[0].product,
            }
        })
    }

    const searchFilter = (productSettingsDistributorTable, searchString) => {
        if (!searchString || searchString?.trim() === '') {
            return productSettingsDistributorTable;
        }
    
        let search = searchString.toLowerCase().trim()
        return productSettingsDistributorTable.filter((distributorRow) =>
            distributorRow.name?.toLowerCase().includes(search)
            || distributorRow.sapId?.toLowerCase().includes(search)
            || (search === 'legal' && distributorRow.legal)
            || (search === 'illegal' && !distributorRow.legal)
            || distributorRow.division?.toLowerCase().includes(search)
            || distributorRow.region?.toLowerCase().includes(search)
            || distributorRow.district?.toLowerCase().includes(search)
            || (distributorRow.skuFulfillmentType?.description?.toLowerCase().includes(search) ?? 'not offered'.includes(search))
            || (distributorRow.skuOfferingStatus?.description?.toLowerCase().includes(search) ?? 'removed'.includes(search)));
    };

    const loadPreparedTable = (productSettingsMap, distributorListSelectedOptionValue, searchString) => {
        productSettingsMap = distributorListFilter(productSettingsMap, distributorListSelectedOptionValue, productSettingsDistributorOverrides);

        productSettingsMap = searchFilter(productSettingsMap, searchString);

        productSettingsMap = productSettingsMap.sort((a, b) => {
            return a.division.localeCompare(b.division) || a.region.localeCompare(b.region) || a.district.localeCompare(b.district) || a.sapId.localeCompare(b.sapId);
        });

        return addLocationHierarchy(productSettingsMap);
    }

    const addLocationHierarchy = (productSettingsMap) => {
        let oldDivision = null;
        let oldRegion = null;
        let oldDistrict = null;
        let tempProductSettingsDistributorTable = [];

        for (let row of productSettingsMap) {
            if (row.division !== oldDivision) {
                tempProductSettingsDistributorTable.push({
                    id: row.division, kind: { value: 0, label: "division" }
                });
                oldDivision = row.division;
            }
            if (row.region !== oldRegion) {
                tempProductSettingsDistributorTable.push({
                    id: row.region, kind: { value: 1, label: "region" }, division: oldDivision
                });
                oldRegion = row.region;
            }
            if (row.district !== oldDistrict) {
                tempProductSettingsDistributorTable.push({
                    id: row.district, kind: { value: 2, label: "district" }, division: oldDivision, region: oldRegion
                });
                oldDistrict = row.district;
            }
            tempProductSettingsDistributorTable.push({
                ...row,
                palletQuantity: row.palletQuantity ?? "",
                palletDivisor: row.palletDivisor ?? "",
                unitWeight: row.unitWeight ?? "",
                kind: { value: 3, label: "distributor" }
            });
        }

        return tempProductSettingsDistributorTable
    }

    const selectAllLegalRows = () => {
        const allIds = productSettingsDistributorTable.filter(row => row.legal !== false).map(row => row.id);
        setSelected(new Set([...allIds]));
    }

    const removeSelectedRows = () => {
        setSelected(new Set());
    }

    useEffect(() => {
        if (unfilteredProductSettingsDistributorTable.length > 0) {
            const preparedTable = loadPreparedTable(unfilteredProductSettingsDistributorTable, distributorListSelectedOption, searchString);
            setProductSettingsDistributorTable(preparedTable);
            setDataLoaded(true);
        } else {
            if (distributors && productSettingsDistributorOverrides.length > 0 && productFulfillment.length > 0) {
                const table = loadDistributorTableSettings(distributors, productSettingsDistributorOverrides, productFulfillment);
                setUnfilteredProductSettingsDistributorTable(table);
                const preparedTable = loadPreparedTable(table, distributorListSelectedOption, searchString);
                setProductSettingsDistributorTable(preparedTable);
                setDataLoaded(true);
            } else {
                setDataLoaded(false);
            }
        }
    }, [distributors, productSettingsDistributorOverrides, productFulfillment, distributorListSelectedOption, unfilteredProductSettingsDistributorTable, searchString]);

    const exceptionsFilter = (table, productSettingsOverrides) => {
        return table.filter(row => productSettingsOverrides.find(override => override.distributorId === row.distributorId));
    }
    
    const noExceptionsFilter = (table, productSettingsOverrides) => {
        return table.filter(row => !productSettingsOverrides.find(override => override.distributorId === row.distributorId));
    }
    
    const distributorListFilter = (productSettingsMap, distributorListSelectedOptionValue, productSettingsDistributorOverrides) => {
        switch (distributorListSelectedOptionValue) {
            case 1:
                return exceptionsFilter(productSettingsMap, productSettingsDistributorOverrides);
            case 2:
                return noExceptionsFilter(productSettingsMap, productSettingsDistributorOverrides);
            default:
                return productSettingsMap;
        }
    }

    return (<ProductSettingsContext.Provider value={{
        dataLoaded,
        productDefaults,
        distributorListSelectedOption,
        setDistributorListSelectedOption,
        productSettingsDistributorTable,
        updateProductSettingsDistributorTable,
        skuFulfillmentTypesByCustomerType,
        getPossibleSkuFulfillmentTypes,
        isChanged,
        searchString,
        setSearchString,
        onRowSelectClick,
        selected,
        isRowSelected,
        isRowCheckboxIndeterminate,
        selectAllLegalRows,
        removeSelectedRows,
        unfilteredProductSettingsDistributorTable
    }}>{children}</ProductSettingsContext.Provider>)
}

export function useProductSettingsContext() {
    const context = useContext(ProductSettingsContext);

    if (!context) throw new Error("useProductSettingsContext must be used within a ProductSettingsContextProvider. Wrap a parent component in <ProductSettingsContextProvider> to fix this error.");
    return context;
}