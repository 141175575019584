import { useEffect, useState } from "react";

export const useFetchSkuFulfillmentTypesByCustomerType = () => {
    const [skuFulfillmentTypesByCustomerType, setSkuFulfillmentTypesByCustomerType] = useState([]);

    useEffect(() => {
        const fetchSkuFulfillmentTypesByCustomerType = async () => {
            const rsp = await fetch(`api/SKUFulfillmentTypes/map`);
            const skuFulfillmentTypesByCustomerType = await rsp.json();
            setSkuFulfillmentTypesByCustomerType(skuFulfillmentTypesByCustomerType);
        }
        fetchSkuFulfillmentTypesByCustomerType();
    }, [])

    return skuFulfillmentTypesByCustomerType;
}