import React, {useContext, useEffect, useState} from "react";

const url = `api/skufulfillmenttypes?`;

export const SKUFulfillmentTypesContext = React.createContext(null);

export function SKUFulfillmentTypesContextProvider(props) {
    const [skuFulfillmentTypes, setSKUFulfillmentTypes] = useState(null);
    const [selectedSKUFulfillmentTypes, setSelectedSKUFulfillmentTypes] = useState();
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                setError(data.error);
                setSKUFulfillmentTypes(data);
                setIsReady(true);
            });
    }, []);

    return (
        <SKUFulfillmentTypesContext.Provider
            value={{
                skuFulfillmentTypes,
                selectedSKUFulfillmentTypes,
                setSelectedSKUFulfillmentTypes,
                isReady,
                error,
            }}
        >
            {props.children}
        </SKUFulfillmentTypesContext.Provider>
    );
}

export function useSKUFulfillmentTypesContext() {
    const context = useContext(SKUFulfillmentTypesContext);

    if (!context)
        throw new Error(
            "useSKUFulfillmentTypes must be used within a SKUFulfillmentTypesProvider. Wrap a parent component in <SKUFulfillmentTypesProvider> to fix this error."
        );
    return context;
}

export function FilterSKUFulfillmentTypes(skuFulfillmentTypes, searchString) {
    return skuFulfillmentTypes.filter(
        (product) =>
            product.description.toLowerCase().includes(searchString)
    );
}