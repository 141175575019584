import React, {useContext, useEffect, useState} from "react";

const url = `api/planningwindow`;

export const PlanningWindowContext = React.createContext(null);

export function PlanningWindowContextProvider(props) {
    const [planningWindows, setPlanningWindows] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                setError(data.error);
                setPlanningWindows(data);
                setIsReady(true);
            });
    }, []);

    return (<PlanningWindowContext.Provider
        value={{
            planningWindows, isReady, error,
        }}
    >
        {props.children}
    </PlanningWindowContext.Provider>);
}

export function usePlanningWindowContext() {
    const context = useContext(PlanningWindowContext);

    if (!context) throw new Error("usePlanningWindowContext must be used within a PlanningWindowContextProvider. Wrap a parent component in <PlanningWindowContextProvider> to fix this error.");
    return context;
}