import React, {useContext, useEffect, useState} from "react";

const url = `skuofferingstatuses?`;

export const SKUOfferingStatusesContext = React.createContext(null);

export function SKUOfferingStatusesContextProvider(props) {
    const [skuOfferingStatuses, setSKUOfferingStatuses] = useState(null);
    const [selectedSKUOfferingStatuses, setSelectedSKUOfferingStatuses] = useState();
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                setError(data.error);
                setSKUOfferingStatuses(data);
                setIsReady(true);
            });
    }, []);

    return (
        <SKUOfferingStatusesContext.Provider
            value={{
                skuOfferingStatuses,
                selectedSKUOfferingStatuses,
                setSelectedSKUOfferingStatuses,
                isReady,
                error,
            }}
        >
            {props.children}
        </SKUOfferingStatusesContext.Provider>
    );
}

export function useSKUOfferingStatusesContext() {
    const context = useContext(SKUOfferingStatusesContext);

    if (!context)
        throw new Error(
            "useSKUOfferingStatusesContext must be used within a SKUOfferingStatusesContextProvider. Wrap a parent component in <SKUOfferingStatusesContextProvider> to fix this error."
        );
    return context;
}

export function FilterSKUOfferingStatuses(skuOfferingStatuses, searchString) {
    return skuOfferingStatuses.filter(
        (skuOfferingStatus) =>
            skuOfferingStatus.description.toLowerCase().includes(searchString)
    );
}