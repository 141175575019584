import React from 'react';
import { Button, Skeleton, Stack, LinearProgress } from "@mui/material";
import DistributorMultiSelection from "../../Components/DistributorMultiSelection";
import ProductMultiSelection from "../../Components/ProductMultiSelection";
import SKUFulfillmentTypeMultiSelection from "../../Components/SKUFulfillmentTypeMultiSelection";
import SKUOfferingStatusMuliSelection from "../../Components/SKUOfferingStatusMultiSelection";
import AvailabitlitySelection from "../../Components/AvailabilitySelection";
import FulfillmentStatusGrid from "../../Components/FulfillmentStatusGrid";
import { useFulfillmentStatusContext } from "../../Contexts/fulfillmentStatusContext";
import { useDistributorsContext } from "../../Contexts/distributorsContext";
import { useProductsContext } from "../../Contexts/productsContext";

export const FulfillmentStatus = () => {
  const { selectedDistributors } = useDistributorsContext();
  const { selectedProducts } = useProductsContext();
  const {
    isReady: areFulfillmentStatusItemsReady,
    fulfillmentStatusItems,
    get,
    isLoading,
    exportCsv,
    isExporting,
  } = useFulfillmentStatusContext();

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        padding={1}
      >
        <ProductMultiSelection />
        <SKUOfferingStatusMuliSelection />
        <AvailabitlitySelection />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        padding={1}
      >
        <DistributorMultiSelection />
        <SKUFulfillmentTypeMultiSelection />
        <Stack direction="row" spacing={5}>
          <Button variant="outlined" onClick={get} sx={{ m: 1, minWidth: 60 }} disabled={isExporting}>
            Search
          </Button>
          <Button variant="outlined" onClick={exportCsv} sx={{ m: 1, minWidth: 60 }} disabled={isExporting}>
            Export
          </Button>
        </Stack>
      </Stack>
      <div>
        {isExporting ? (
          <LinearProgress color="info" />
        ) : ((!selectedDistributors || selectedDistributors.length === 0) && (!selectedProducts || selectedProducts.length === 0)) || !areFulfillmentStatusItemsReady ? (
          <center style={{ padding: 25 }}>
            <h4>
              Please choose at least one product or distributor and then search OR export for all products/distributors
            </h4>
          </center>
        ) : isLoading ? (
          <Skeleton variant="rectangular" height={400} />
        ) : fulfillmentStatusItems.length <= 0 &&
          areFulfillmentStatusItemsReady ? (
          <center style={{ padding: 25 }}>
            <h4>There is no data for that matches the specified filters</h4>
          </center>
        ) : (
          <FulfillmentStatusGrid />
        )}
      </div>
    </div>
  );
};